import { TaxCategory } from '../types/taxCategory';
import axios from './index';

class TaxCategoryApi {
  static base = '/taxCategory';

  static getTaxCategories(): Promise<{
    data: {
      data: TaxCategory[];
    };
  }> {
    return axios.get(`${this.base}`);
  }

  static getTaxCategoriesByIndustryCode(industryCode: number): Promise<any> {
    return axios.get(`${this.base}/${industryCode}`);
  }
}

export default TaxCategoryApi;
