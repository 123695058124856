import axios from './index';
import { AxiosResponse } from 'axios';
import User from '../types/user';

interface LoginResponse {
  success: boolean;
  user: {
    username: string;
  };
  token: string;
}

interface AuthData {
  [key: string]: any;
}

class AuthApi {
  static base = 'auth'; // Adjusted base URL to match Django's URL pattern

  static Register = (data: AuthData): Promise<AxiosResponse> => {
    return axios.post(`${this.base}/register`, data);
  };

  static Logout = (): Promise<void> => {
    return axios
      .post(`${this.base}/logout`)
      .then((response: AxiosResponse) => {
        console.log('Logout successful:', response.data); // Log successful response data
      })
      .catch((error: any) => {
        console.error(
          'Logout failed:',
          error.response ? error.response.data : error
        ); // Log error response or error
        throw error;
      });
  };

  static async authenticateEmailPassword(
    email: string,
    password: string
  ): Promise<User> {
    const response = await axios.post(`${this.base}/login`, {
      email,
      password,
    });
    const { refreshToken, userEmail } = response.data;
    const user: User = {
      refreshToken: refreshToken,
      userEmail: userEmail,
    };
    return user;
  }

  static async authenticateGoogle(code: string): Promise<User> {
    const response = await axios.post(`${this.base}/google`, {
      code,
    });
    const { refreshToken, email } = response.data;
    const user: User = {
      refreshToken: refreshToken,
      userEmail: email,
    };
    console.log(user);
    localStorage.setItem('user', JSON.stringify(user));
    return user;
  }

  /**
   * sends request to backend to check if user is authenticated
   * @returns user object if authenticated
   */
  static async verifyAuth(): Promise<User | null> {
    const response = await axios.get(`${this.base}/isAuthenticated`);

    if (response.data && response.data.email && response.data.refreshToken) {
      let user: User = {
        refreshToken: response.data.refreshToken,
        userEmail: response.data.email,
      };
      console.log(user);
      localStorage.setItem('user', JSON.stringify(user));
      return user;
    }

    return null;
  }
}

export default AuthApi;
