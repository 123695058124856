// entityApi.tsx
import axios from './index';

interface EntityData {
  name: string;
  type?: string;
  sentDocuments?: string[];
  receivedDocuments?: string[];
}

class EntityApi {
  static base = '/entities';

  static createEntity(data: EntityData, token: string): Promise<any> {
    return axios.post(`${this.base}/`, data, {
      headers: { Authorization: `${token}` },
    });
  }

  static getEntity(id: string, token: string): Promise<any> {
    return axios.get(`${this.base}/getEntity/?id=${id}`, {
      headers: { Authorization: `${token}` },
    });
  }

  static getTenants(token: string): Promise<any> {
    return axios.get(`${this.base}/getTenants`, {
      headers: { Authorization: `${token}` },
    });
  }
}

export default EntityApi;
