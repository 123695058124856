export interface AccountConfig {
  _id: string;
  inputFolder: string;
  organizedFolder: string;
  sheetDocument: string;
  fileNameSchema: object[];
  filePathSchema: object[];
  autoFileDocuments: boolean;
  dashboard: Configuration;
  renamingLab: Configuration;
  statementLab: Configuration;
}

export enum Configuration {
  disabled,
  limited,
  full,
  etransfer,
}
