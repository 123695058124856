import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import User from '../types/user';
import AuthApi from '../api/auth';

interface AuthContextType {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  userData: User | string | null;
}

export const AuthProvider: React.FC<AuthProviderProps & { children: ReactNode }> = ({ userData, children }): JSX.Element => {
  const [user, setUser] = useState<User | null>(() => {
    if (typeof userData === 'string') {
      try {
        return JSON.parse(userData) as User;
      } catch (error) {
        console.error('Failed to parse userData:', error);
        return null;
      }
    } else if (userData && typeof userData === 'object') {
      return userData as User;
    }
    return null;
  });

  useEffect(() => {
    authenticateUser();
  }, []);

  const authenticateUser = async () => {
    let account = await AuthApi.verifyAuth();
    if (account) {
      localStorage.setItem('user', JSON.stringify(account));
      setUser(account);
    }
    else {
      setUser(null);
      localStorage.removeItem('user');
    }
  }

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
