/**
=========================================================
* Sui UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/sui-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import colors from '../../assets/theme/base/colors';
import styles from './styles';

// Define the types for the props
interface SuiSelectProps {
  size?: 'small' | 'medium' | 'large';
  error?: boolean;
  success?: boolean;
  [key: string]: any; // To allow any additional props
}

const SuiSelect = forwardRef<HTMLDivElement, SuiSelectProps>(
  ({ size = 'medium', error = false, success = false, ...rest }, ref) => {
    const { light } = colors;

    return (
      <Select
        {...rest}
        styles={styles(size, error, success)}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: light.main,
            primary: light.main,
          },
        })}
      />
    );
  }
);

SuiSelect.displayName = 'SuiSelect';

export default SuiSelect;
