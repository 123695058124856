/**
=========================================================
* Sui UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/sui-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */

import { forwardRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import { GroupBase, Props } from 'react-select';
import colors from '../../assets/theme/base/colors';
import styles from './styles';

interface SuiCreatableSelectProps extends Props {
  size?: 'small' | 'medium' | 'large';
  error?: boolean;  
  success?: boolean;

  [key: string]: any;
}

const SuiCreatableSelect = forwardRef<any, SuiCreatableSelectProps>(
  ({ size = 'medium', error = false, success = false, ...rest }, ref) => {
    const { light } = colors;

    return (
      <CreatableSelect
        {...rest}
        ref={ref}
        styles={{
          ...styles(size, error, success),
          ...rest.styles
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: light.main,
            primary: light.main,
          },
        })}
      />
    );
  }
);
/* eslint-enable */

SuiCreatableSelect.displayName = 'SuiCreatableSelect';

export default SuiCreatableSelect;
