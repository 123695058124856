import SuiInput from '../../../components/SuiInput';
import React, { useEffect, useRef, useState } from 'react';
import { FileDataItem, FileDataStatementItem } from '../../../types/fileDataItem';
import IconButton from '@mui/material/IconButton';
import { AttributeTypeEnum } from '../../../utils/AttributeTypeEnum';
import SuiBox from '../../../components/SuiBox';
import { Pagination } from '@mui/material';
import SuiTypography from '../../../components/SuiTypography';
import SuiSelect from '../../../components/SuiSelect';
import TaxCategoryApi from '../../../api/taxCategoryApi';
import { TaxCategory } from '../../../types/taxCategory';

const StatementPDFViewer = ({
    fileData,
    setFileData,
    setMadeUnsavedChanges,
    fileId,
    handleChange,
    renderListItem
} : {
    fileData: any,
    setFileData: any,
    setMadeUnsavedChanges: any,
    fileId: string,
    handleChange: any,
    renderListItem: any,
}) : React.JSX.Element => {
    const [statementItems, setStatementItems] = useState<FileDataStatementItem[]| null>(null);
    const [taxCategoriesOptions, setTaxCategoriesOptions] = useState<{
        value: string,
        label: string
    }[]>([]);
    const [page, setPage] = React.useState(1);

    const itemsPerPage = 50;
    const amountRefs = useRef<(HTMLDivElement | null)[]>([]);

    const divStyle = {
        marginTop: '14px',
        marginBottom: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };

    useEffect(() => {
        const fetchTaxCategories = async () => {
            const response = await TaxCategoryApi.getTaxCategories();
            const taxCategories: TaxCategory[] = response.data.data;
            let options: {
                value: string,
                label: string
            }[] = [];
            for (const taxCategory of taxCategories) {
                for (const industrySubsector of taxCategory.industrySubsectors) {
                    for (const category of industrySubsector.category) {
                        options.push({
                            value: String(category.industryCode),
                            label: `${category.industryCode} - ${category.businessActivity}`
                        });
                    }
                }
            }
            console.log(options);
            setTaxCategoriesOptions(options);
        };
        fetchTaxCategories();
    }, []);


    useEffect(() => {
        let openingBalance = Number(fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE]
            .find(item => item.name === 'diaOpeningBalance')?.value || 0);
        let closingBalance = Number(fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE]
            .find(item => item.name === 'diaClosingBalance')?.value || 0);
        let balanceDifference = Number(((closingBalance || 0) - (openingBalance || 0)).toFixed(2));
        let debits = fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE]
            .reduce((acc, item) => acc + (item.amount > 0 ? item.amount : 0), 0)
        debits = Number(debits.toFixed(2));
        let credits = fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE]
            .reduce((acc, item) => acc + (item.amount < 0 ? item.amount : 0), 0)
        credits = Number(credits.toFixed(2));

        let suggestedDifference = Number((Number(debits) + Number(credits)).toFixed(2));

        let statementItems: FileDataStatementItem[] = [];

        statementItems.push({
            type: 'double',
            name: 'diaOpeningBalance',
            fileDataItems: [
            {
                ...fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE].find(item => item.name === 'diaOpeningBalance'),
                amount: openingBalance,
                index: fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE].findIndex(item => item.name === 'diaOpeningBalance')
            },
            {
                label: 'debits',
                amount: debits,
                disabled: true
            }
        ]
    });
    
        statementItems.push({
          type: 'double',
          name: 'diaClosingBalance',
          fileDataItems: [
            {
              ...fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE].find(item => item.name === 'diaClosingBalance'),
              amount: closingBalance,
              index: fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE].findIndex(item => item.name === 'diaClosingBalance')
            },
            {
              label: 'credits',
              amount: credits,
              disabled: true
            }
          ]
        });
    
        statementItems.push({
          type: 'double',
          name: 'diaBalanceDifference',
          fileDataItems: [
            {
              label: '+/-',
              name: 'diaBalanceDifference',
              amount: balanceDifference,
              disabled: true
            },
            {
              label: 'balance',
              name: 'diaSuggestedDifference',
              amount: suggestedDifference,
              disabled: true
            }
          ]
        });
    
        let reconciliationDifference = balanceDifference == suggestedDifference ? 'balanced' : (balanceDifference - suggestedDifference).toFixed(2);
        statementItems.push({
          type: 'single',
          name: 'diaReconciliation',
          fileDataItems: [
            {
              label: 'balance',
              value: reconciliationDifference,
              disabled: true
            }
          ]
        });
    
        console.log(statementItems);
        setStatementItems(statementItems);
      }, [fileData]);


    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    
    const addRow = (index: number) => {
        const newData = { ...fileData };
        const defaultRow: FileDataItem = {
          transactionDate: new Date().toISOString().split('T')[0],
          transactionDetails: '',
          amount: 0,
        };
      
        newData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].splice(index + 1, 0, defaultRow);
        setFileData(newData);
        setMadeUnsavedChanges(true);
      };
      const deleteRow = (index: number) => {
        console.log(fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].length);
        if (fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].length <= 1) {
          return;
        }
        const newData = { ...fileData };
        newData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].splice(index, 1);
        setFileData(newData);
        setMadeUnsavedChanges(true);
      };


      const handleStatementChange = (index: number, field: 'transactionDate' | 'transactionDetails' | 'amount' | 'category', value: string | number) => {
        const newData = { ...fileData };
        newData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE][index] = {
          ...newData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE][index],
          [field]: field === 'category' || field === 'amount' ? Number(value) : value
        };
        setFileData(newData);
        setMadeUnsavedChanges(true);
      };
    

      const renderStatementGeneralFields = (item: FileDataStatementItem, index: number) => {
        return (
          <div
            key={`StatementGeneralFields-${fileId}-${index}`}
          >
          {
            item.type === 'double' && (
              <SuiBox>
                <table style={{ width: '100%', marginTop: '8px' }}>
                  <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '30%' }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      {item.fileDataItems.map((fileDataItem, idx) => (
                        <React.Fragment key={`StatementGeneralFields-${fileId}-${index}-${idx}`}>
                          <td key={`label-${fileId}-${fileDataItem.key}-${idx}`} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                            <SuiTypography variant="caption" fontWeight="bold" style={{ fontSize: '16px' }}>
                              {fileDataItem.label}
                            </SuiTypography>
                          </td>
                          <td key={`value-${fileId}-${fileDataItem.key}-${idx}`} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                              <SuiInput
                                value={fileDataItem.amount}
                                type="number"
                                step="0.01"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  let value = event.target.value;
                                  // Use regex to allow only up to two decimal places
                                  if (/^-?\d*\.?\d{0,2}$/.test(value)) {
                                    handleChange(fileDataItem.index, value, AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE);
                                  }
                                }}
                                onKeyDown={(event) => {
                                  if (event.key === 't') {
                                    const currentValue = Number(event.target.value);
                                    handleChange(fileDataItem.index, (-currentValue).toString(), AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE);
                                  }
                                }}
                                disabled={fileDataItem.disabled}
                              />
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </SuiBox>
            )
          }
          {
            item.type === 'single' && item.name === 'diaReconciliation' && (
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop="12px"
              >
                <SuiTypography 
                  variant="caption" 
                  fontWeight="bold" 
                  style={{ 
                    fontSize: '16px',
                    color: item.fileDataItems[0].value.toLowerCase().includes('balanced') ? '#2e7d32' : '#d32f2f'
                  }}
                >
                  {
                    item.fileDataItems[0].value.toLowerCase().includes('balanced') ? 'Balanced' : 'Unbalanced: ' +
                    item.fileDataItems[0].value
                  }
                </SuiTypography>
              </SuiBox>
            )
          }
          </div>
        );
      };

    const renderStatementSpecificFields = (item: FileDataItem, index: number, identifier: string = "statement") => {
        return (
          <tr 
            key={`${fileId}-${identifier}-${index}`}
          >
            <td>
              <SuiInput
                key={`${fileId}-date-${index}`}
                type="date"
                value={item.transactionDate || ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleStatementChange(index + (page - 1) * itemsPerPage, 'transactionDate', event.target.value)
                }
                aria-label={item.transactionDate}
              />
            </td>
            <td>
              <SuiInput
                key={`${fileId}-details-${index}`}
                value={item.transactionDetails || ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleStatementChange(index + (page - 1) * itemsPerPage, 'transactionDetails', event.target.value)
                }
                aria-label={item.transactionDetails}
              />
            </td>
            <td
                ref={(el) => {
                  amountRefs.current[index] = el;
                }}
            >
              <SuiInput
                key={`${fileId}-amount-${index}`}
                value={item.amount || ''}
                type="number"
                step="0.01"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  let value = event.target.value;
                  // Use regex to allow only up to two decimal places
                  value = Number(value).toFixed(2);
                  handleStatementChange(index + (page - 1) * itemsPerPage, 'amount', Number(value));
                }}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if(event.key == 't') {
                    const currentValue = Number((event.target as HTMLInputElement).value);
                    handleStatementChange(index + (page - 1) * itemsPerPage, 'amount', (-currentValue).toString());
                  }
                  if (event.key == 's') {
                    const nextRef = amountRefs.current[index + 1];
                    if (nextRef) {
                      const inputs = nextRef.querySelectorAll('input');
                      inputs.forEach(input => {
                        input.focus();
                        input.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        input.select();
                      });
                    } else {
                      // At last item in current page, go to next page
                      const totalPages = Math.ceil(fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].length / itemsPerPage);
                      if (page < totalPages) {
                        setPage(page + 1);
                        // Focus first item on next page after render
                        setTimeout(() => {
                          const firstRef = amountRefs.current[0];
                          if (firstRef) {
                            const inputs = firstRef.querySelectorAll('input');
                            inputs.forEach(input => {
                              input.focus();
                              input.scrollIntoView({ behavior: 'smooth', block: 'center' });
                              input.select();
                            });
                          }
                        }, 0);
                      }
                    }
                    event.preventDefault();
                  }
                  if (event.key == 'w') {
                    const prevRef = amountRefs.current[index - 1];
                    if (prevRef) {
                      const inputs = prevRef.querySelectorAll('input');
                      inputs.forEach(input => {
                        input.focus();
                        input.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        input.select();
                      });
                    } else {
                      // At first item in current page, go to previous page
                      if (page > 1) {
                        setPage(page - 1);
                        // Focus last item on previous page after render
                        setTimeout(() => {
                          const lastIndex = Math.min(itemsPerPage - 1, fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].length - 1);
                          const lastRef = amountRefs.current[lastIndex];
                          if (lastRef) {
                            const inputs = lastRef.querySelectorAll('input');
                            inputs.forEach(input => {
                              input.focus();
                              input.scrollIntoView({ behavior: 'smooth', block: 'center' });
                              input.select();
                            });
                          }
                        }, 0);
                      }
                    }
                    event.preventDefault();
                  }
                  if (event.key == 'd') {
                    deleteRow(index + (page - 1) * itemsPerPage);
                  }
                  if (event.key == 'a') {
                    addRow(index + (page - 1) * itemsPerPage);
                  }
                }}
                aria-label={item.id}
              />
            </td>
            <td>
              <SuiSelect
                key={`${fileId}-category-${index}`}
                value={
                    taxCategoriesOptions.find(option => option.value === String(item.category))
                }
                options={taxCategoriesOptions}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key == '+') {
                      const nextRef = amountRefs.current[index + 1];
                      if (nextRef) {
                        const inputs = nextRef.querySelectorAll('input');
                        inputs.forEach(input => {
                          input.focus();
                          input.scrollIntoView({ behavior: 'smooth', block: 'center' });
                          input.select();
                        });
                      } else {
                        // At last item in current page, go to next page
                        const totalPages = Math.ceil(fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].length / itemsPerPage);
                        if (page < totalPages) {
                          setPage(page + 1);
                          // Focus first item on next page after render
                          setTimeout(() => {
                            const firstRef = amountRefs.current[0];
                            if (firstRef) {
                              const inputs = firstRef.querySelectorAll('input');
                              inputs.forEach(input => {
                                input.focus();
                                input.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                input.select();
                              });
                            }
                          }, 0);
                        }
                      }
                      event.preventDefault();
                    }
                  }}
                onChange={(event) => {
                  console.log(event);
                  handleStatementChange(index, 'category', event.value);
                }}
                aria-label={item.category}
              />
            </td>
            <td>
              <IconButton 
                onClick={() => addRow(index + (page - 1) * itemsPerPage)} 
                size="small"
                sx={{ padding: 0, marginLeft: '10px' }}
              >
                +
              </IconButton>
              <IconButton 
                onClick={() => deleteRow(index + (page - 1) * itemsPerPage)} 
                size="small"
                sx={{ padding: 0, marginLeft: '12px' }}
              >
                -
              </IconButton>
            </td>
          </tr>
        );
      };
    return (
        <>
            {fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE].map(
                (item, index) => item.name === 'diaOpeningBalance' || item.name === 'diaClosingBalance' ? null : renderListItem(item, index)
            )}

              <SuiTypography variant="caption" fontWeight="bold" style={{ fontSize: '16px', ...divStyle }}>
                Statement Attributes
              </SuiTypography>
              {statementItems && 
                statementItems.map((item, index) => renderStatementGeneralFields(item, index))
              }

        <SuiBox>
            <table style={{ width: '100%', marginBottom: '8px' }}>
            <colgroup
            >
            <col style={{ width: '20%' }} />
            <col style={{ width: '30%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '35%' }} />
            </colgroup>
            <thead
                >
            <tr            >
                <th>Date</th>
                <th>Transaction Details</th>
                <th>Amount</th>
                <th>Category</th>
            </tr>
            </thead>
            <tbody>
            {fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE]
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map((item, index) => {
                return renderStatementSpecificFields(
                    item, 
                    index,
                    AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE
                )
                })}
            </tbody>
        </table>

        <SuiBox display="flex" justifyContent="center" mt={2}>
                <Pagination
                    count={Math.ceil(fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].length / itemsPerPage)}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                />
                </SuiBox>
        </SuiBox>
        </>
    );
};

export default StatementPDFViewer;
