import React, {useEffect, useRef, useState} from 'react';
import SuiBox from '../../../components/SuiBox';
import SuiInput from '../../../components/SuiInput';
import SuiCreatableSelect from '../../../components/SuiCreatableSelect';
import SuiTypography from '../../../components/SuiTypography';
import DocumentApi from '../../../api/documentApi';
import {AttributeTypeEnum} from '../../../utils/AttributeTypeEnum';
import {FileDataItem, FileDataStatementItem} from '../../../types/fileDataItem';
import {FileData} from '../../../types/fileData';
import FlashingBox from './FlashingBox';
import IconButton from '@mui/material/IconButton';
import {DownloadOutlined, OpenInNew} from '@mui/icons-material';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import {DocumentTypeEnum} from '../../../utils/DocumentTypeEnum';
import StatementPDFViewer from './StatementPDFViewer';

interface PDFInfoDisplayProps {
  user: {
    refreshToken: string;
  };
  fileData: FileData;
  fileId: string;
  setFileData: (data: FileData) => void;
  setMadeUnsavedChanges: (changed: boolean) => void;
  processNewFields: ({diaType}: { diaType: string }) => Promise<void>;
  documentType: string;
  downloadStatement: () => Promise<void>;
  isSaving: boolean;
  tenantMap: any;
}

const PDFInfoDisplay: React.FC<PDFInfoDisplayProps> = ({
                                                         user,
                                                         fileData,
                                                         fileId,
                                                         setFileData,
                                                         setMadeUnsavedChanges,
                                                         processNewFields,
                                                         documentType,
                                                         downloadStatement,
                                                         isSaving,
                                                         tenantMap
                                                       }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [statementItems, setStatementItems] = useState<FileDataStatementItem[] | null>(null);


  const [statementCalculations, setStatementCalculations] = useState<{
    creditAmount: number;
    debitAmount: number;
  }>({
    creditAmount: 0,
    debitAmount: 0,
  });

  const convertToStatementItem = (item: FileDataItem) => {
    if (item.name === 'diaOpeningBalance') {
      return {
        ...item,
        label1: 'opening balance',
        value1: item.value,
        label2: 'debits',
        value2: String(statementCalculations.debitAmount)
      }
    }
    if (item.name === 'diaClosingBalance') {
      return {
        ...item,
        label1: 'closing balance',
        value1: item.value,
        label2: 'credits',
        value2: String(statementCalculations.creditAmount)
      }
    }
    return item;
  }

  useEffect(() => {
    setIsLoading(true);
    // Add a cleanup function to handle component unmounting
    return () => setIsLoading(false);
  }, [fileId]);

  useEffect(() => {
    // When fileData changes, we can assume the data is ready
    setIsLoading(false);
  }, [fileData]);

  useEffect(() => {
    let openingBalance = Number(fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE]
      .find(item => item.name === 'diaOpeningBalance')?.value || 0);
    let closingBalance = Number(fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE]
      .find(item => item.name === 'diaClosingBalance')?.value || 0);
    let balanceDifference = Number(((closingBalance || 0) - (openingBalance || 0)).toFixed(2));
    let debits = fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE]
      .reduce((acc, item) => acc + (item.amount > 0 ? item.amount : 0), 0)
    debits = Number(debits.toFixed(2));
    let credits = fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE]
      .reduce((acc, item) => acc + (item.amount < 0 ? item.amount : 0), 0)
    credits = Number(credits.toFixed(2));

    let suggestedDifference = Number((Number(debits) + Number(credits)).toFixed(2));

    let statementItems: FileDataStatementItem[] = [];

    statementItems.push({
      type: 'double',
      name: 'diaOpeningBalance',
      fileDataItems: [
        {
          ...fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE].find(item => item.name === 'diaOpeningBalance'),
          amount: openingBalance,
          index: fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE].findIndex(item => item.name === 'diaOpeningBalance')
        },
        {
          label: 'debits',
          amount: debits,
          disabled: true
        }
      ]
    });

    statementItems.push({
      type: 'double',
      name: 'diaClosingBalance',
      fileDataItems: [
        {
          ...fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE].find(item => item.name === 'diaClosingBalance'),
          amount: closingBalance,
          index: fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE].findIndex(item => item.name === 'diaClosingBalance')
        },
        {
          label: 'credits',
          amount: credits,
          disabled: true
        }
      ]
    });


    statementItems.push({
      type: 'double',
      name: 'diaBalanceDifference',
      fileDataItems: [
        {
          label: '+/-',
          name: 'diaBalanceDifference',
          amount: balanceDifference,
          disabled: true
        },
        {
          label: 'balance',
          name: 'diaSuggestedDifference',
          amount: suggestedDifference,
          disabled: true
        }
      ]
    });

    let reconciliationDifference = balanceDifference == suggestedDifference ? 'balanced' : (balanceDifference - suggestedDifference).toFixed(2);
    statementItems.push({
      type: 'single',
      name: 'diaReconciliation',
      fileDataItems: [
        {
          label: 'balance',
          value: reconciliationDifference,
          disabled: true
        }
      ]
    });

    console.log(statementItems);
    setStatementItems(statementItems);
  }, [fileData]);

  useEffect(() => {
    if (documentType == 'statement') {
      //load balancing items
      let creditAmount = 0;
      let debitAmount = 0;
      for (let i = 0; i < fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].length; i++) {
        if (fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE][i].amount < 0) {
          creditAmount += fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE][i].amount;
        } else {
          debitAmount += fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE][i].amount;
        }
      }
      setStatementCalculations({
        creditAmount,
        debitAmount
      });
    }
  }, [fileData]);


  useEffect(() => {
    setIsLoading(true);
    return () => setIsLoading(false);
  }, [fileId]);

  useEffect(() => {
    setIsLoading(false);
  }, [fileData]);


  const divStyle = {
    marginTop: '14px',
    marginBottom: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  let amountRefs = useRef<(HTMLElement | null)[]>([]);

  const handleStatementChange = (index: number, field: 'transactionDate' | 'transactionDetails' | 'amount', value: string | number) => {
    const newData = {...fileData};
    newData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE][index] = {
      ...newData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE][index],
      [field]: value
    };
    setFileData(newData);
    setMadeUnsavedChanges(true);
  };

  const handleChange = async (index: number, value: string, documentType: string): Promise<void> => {
    console.log(index, value, documentType);
    setMadeUnsavedChanges(true);
    const newData = {...fileData};
    if (fileData?.[documentType]?.[index]?.name === 'diaType') {
      let oldDIAType = fileData[documentType][index].value;
      if (oldDIAType != value) {
        processNewFields({
          diaType: value
        });
      }
    }
    if (
      fileData?.[documentType]?.[index]?.inputType === 'dropdown' &&
      fileData?.[documentType]?.[index]?.options &&
      !fileData?.[documentType]?.[index]?.options.includes(value)
    ) {
      const newAttribute = newData[documentType];
      newAttribute[index].options?.push(value);
      newData[documentType] = newAttribute;
    }
    if (fileData[documentType][index].inputType === 'dropdown-action') {
      // handle tenant only
      newData[documentType][index].value = value;
      newData[documentType][index].id = tenantMap[value]._id;
      newData[documentType][index].name = 'entityRef';
      newData[documentType][index].rentId = tenantMap[value].rentId;
    }
    newData[documentType][index].value = value;

    setFileData(newData);
  };

  const renderListItem = (item: FileDataItem, index: number) => {
    return (
      <SuiBox
        key={fileId + "-" + item.key}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="8px"

      >
        {item.inputType != 'hidden' && (
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            style={{fontSize: '16px'}}
          >
            {item.label}
          </SuiTypography>
        )}
        <div style={{width: '60%'}}>
          {item.loading && (
            <FlashingBox/>
          )}
          {!item.loading && (
            <>
              {item.inputType === 'dropdown' && (
                <>
                  <SuiCreatableSelect
                    success={!item.options?.includes(item.value)}
                    onChange={({value}: { value: string }) =>
                      handleChange(index, value, item.attributeType)
                    }
                    onCreateOption={async (value: string) => {
                      let type = item.entityType || item.name;
                      await DocumentApi.addGeneralFieldOption(
                        type,
                        value,
                        user.refreshToken
                      );
                      handleChange(index, value, item.attributeType);
                    }}
                    options={item.options?.map((option) => ({
                      label: option,
                      value: option,
                    })) || []}
                    value={{label: item.value, value: item.value}}
                  />
                </>
              )}
              {item.inputType === 'dropdown-action' && (
                <SuiBox display="flex" gap={1}>
                  <SuiBox flex={1}>
                    <SuiCreatableSelect
                      size="large"
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '80px',
                          fontSize: '16px'
                        })
                      }}
                      success={!item.options?.includes(item.value)}
                      onChange={({value}: { value: string }) =>
                        handleChange(index, value, item.attributeType)
                      }
                      onCreateOption={async (value: string) => {
                        let type = item.entityType || item.name;
                        await DocumentApi.addGeneralFieldOption(
                          type,
                          value,
                          user.refreshToken
                        );
                        handleChange(index, value, DocumentTypeEnum.PAYMENT_CONFIRMATION);
                      }}
                      options={item.options?.map((option) => ({
                        label: option,
                        value: option,
                      })) || []}
                      value={{label: item.value, value: item.value}}
                    />
                  </SuiBox>
                  <IconButton
                    onClick={() => window.open('https://dashboard.plexflow.ca/app/fr/rent/' + item.rentId, '_blank')}
                  >
                    <OpenInNew/>
                  </IconButton>
                </SuiBox>
              )}
              {(item.inputType === 'editable' || item.inputType === 'date') && (
                <>
                  <SuiInput
                    type={item.inputType === 'date' ? 'month' : 'text'}
                    defaultValue={item.value || ''}
                    onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                      handleChange(index, event.target.value, item.attributeType)
                    }
                    aria-label={item.label}
                  />
                </>
              )}
              {item.inputType == 'disabled' && (
                <>
                  {item.value.startsWith('https') ? (
                    <a href={item.value} target="_blank" rel="noopener noreferrer">
                      <SuiTypography
                        variant="caption"
                        fontWeight="bold"
                        style={{fontSize: '16px', color: 'blue', textDecoration: 'underline'}}
                      >
                        {item.value}
                      </SuiTypography>
                    </a>
                  ) : (
                    <SuiTypography
                      key={fileId + "-" + item.key}
                      variant="caption"
                      fontWeight="bold"
                      style={{fontSize: '16px'}}
                    >
                      {item.value}
                    </SuiTypography>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </SuiBox>)
  };

  const [page, setPage] = React.useState(1);
  const itemsPerPage = 50;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const addRow = (index: number) => {
    const newData = {...fileData};
    const defaultRow: FileDataItem = {
      transactionDate: new Date().toISOString().split('T')[0],
      transactionDetails: '',
      amount: 0,
    };

    newData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].splice(index + 1, 0, defaultRow);
    setFileData(newData);
    setMadeUnsavedChanges(true);
  };
  const deleteRow = (index: number) => {
    console.log(fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].length);
    if (fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].length <= 1) {
      return;
    }
    const newData = {...fileData};
    newData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].splice(index, 1);
    setFileData(newData);
    setMadeUnsavedChanges(true);
  };

  return (
    <SuiBox key={fileId} width="100%" overflowY="auto">
      {isLoading ? (
        <SuiBox display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress/>
        </SuiBox>
      ) : (
        <SuiBox
          style={{
            lineHeight: '1'
          }}
        >
          {documentType != 'statement' ? (
            <>
              {fileData[AttributeTypeEnum.DOCUMENT_ATTRIBUTE].length !== 0 && <div style={divStyle}> Document General Attributes</div>}
              {fileData[AttributeTypeEnum.DOCUMENT_ATTRIBUTE].map((item, index) =>
                renderListItem(item, index)
              )}
            </>
          ) : (
            <SuiBox
            style={{
              ...divStyle,
              marginBottom: '14px',
              display: 'flex',
              alignItems: 'center'
              }}>
              <div style={divStyle}> Statement Attributes</div>
              <div>
              {isSaving ? <CircularProgress /> :
              <IconButton
                onClick={downloadStatement}
                sx={{
                  marginTop: '10px',
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }}
              >
                <DownloadOutlined />
              </IconButton>
              }
            </div>
            </SuiBox>
          )}

          {documentType != 'statement' ? (
            <>
              {fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE].length !== 0 &&
                <div style={divStyle}> Document Identity Attributes</div>}
              {fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE].map(
                (item, index) => renderListItem(item, index)
              )}
            </>
          ) : (
            <>
            </>
          )}

          {documentType != 'statement' ?
          <SuiBox>
            {fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].length !== 0 && 
              <div style={divStyle}> Document Specific Attributes </div>
            }
            {fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].map(
              (item, index) => renderListItem(item, index)
            )}
          </SuiBox>
          : 
          <SuiBox>
            <StatementPDFViewer 
              fileData={fileData} 
              setFileData={setFileData} 
              setMadeUnsavedChanges={setMadeUnsavedChanges} 
              fileId={fileId}  
              handleChange={handleChange}
              renderListItem={renderListItem}
            />
          </SuiBox>
          }
        </SuiBox>
      )}
    </SuiBox>
  );
};

export default PDFInfoDisplay;

