import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import Footer from '../../examples/Footer';
import FileTable from './components/Files/';
import FileLinks from '../../components/FileLinks/fileLinks';
import { AccountConfig } from '../../types/accountConfig';
import AccountApi from '../../api/accountApi';
import { useAuth } from '../../auth-context/auth.context';
import { Configuration } from '../../types/accountConfig';

const Dashboard: React.FC = () => {
  const [accountConfig, setAccountConfig] = useState<AccountConfig | null>(null);
  const {user, setUser} = useAuth();

  setUser(user);

  useEffect(() => {
    const fetchAccountConfig = async () => {
      console.log("user", user);
      let accountConfigResponse = await AccountApi.getAccountConfig();
      setAccountConfig(accountConfigResponse.data);
    };
    fetchAccountConfig();
  }, [user]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {accountConfig && accountConfig?.dashboard !== Configuration.disabled && accountConfig?.dashboard !== Configuration.limited && (
        <FileLinks includedLinks={[ (accountConfig?.dashboard === Configuration.full ? 'inputFolder' : ''),'organizedDocuments']} />
      )}
      <FileTable />
      <Footer />
    </DashboardLayout>
  );
};

export default Dashboard;
