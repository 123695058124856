/**
 =========================================================
 * Soft UI Dashboard React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from '@mui/material/Grid';

// @mui icons
// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import { RELEASE_TAG } from '../../../../config/constant';

function Footer() {
  return (
    <SuiBox component="footer" py={6}>
      <Grid container justifyContent="center">
        <Grid item xs={10} lg={8}>
          <SuiBox display="flex" justifyContent="center" flexWrap="wrap" mb={3}>
            <SuiBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              {/* <SuiTypography
                component="a"
                href="https://mcgillai.com"
                target="_blank"
                variant="body2"
                textColor="secondary"
              >
                MAIS Kernel
              </SuiTypography>
            </SuiBox>
            <SuiBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <SuiTypography
                component="a"
                href="https://ensemble-technologies.com/"
                target="_blank"
                variant="body2"
                textColor="secondary"
              >
                Ensemble
              </SuiTypography>
            </SuiBox>
            <SuiBox mr={{ xs: 0, lg: 3, xl: 6 }}>
              <SuiTypography
                component="a"
                href="https://marckoran.com/"
                target="_blank"
                variant="body2"
                textColor="secondary"
              >
                Marc Koran
              </SuiTypography> */}
            </SuiBox>
          </SuiBox>
        </Grid>
        {/* <Grid item xs={12} lg={8}>
          <SuiBox display="flex" justifyContent="center" mt={1} mb={3}>
            <SuiBox mr={3} color="secondary">
              <FacebookIcon fontSize="small" />
            </SuiBox>
            <SuiBox mr={3} color="secondary">
              <TwitterIcon fontSize="small" />
            </SuiBox>
            <SuiBox mr={3} color="secondary">
              <InstagramIcon fontSize="small" />
            </SuiBox>
            <SuiBox mr={3} color="secondary">
              <PinterestIcon fontSize="small" />
            </SuiBox>
            <SuiBox color="secondary">
              <LinkedInIcon fontSize="small" />
            </SuiBox>
          </SuiBox>
        </Grid> */}
        <Grid item xs={12} lg={8} className="text-center">
          <SuiTypography variant="body2" textColor="secondary">
            App Version: {RELEASE_TAG}
          </SuiTypography>
          <SuiTypography variant="body2" textColor="secondary">
            &nbsp;&copy; Ensemble Technologies Inc.
          </SuiTypography>
          <br />
          <br />
          <SuiTypography
            component="a"
            href="https://www.tryensemble.com/privacy-policy"
            variant="body2"
            textColor="secondary"
          >
            Privacy Policy
          </SuiTypography>
          &nbsp;&nbsp;
          <SuiTypography
            component="a"
            href="https://www.tryensemble.com/terms-of-service"
            variant="body2"
            textColor="secondary"
          >
            Terms of Service
          </SuiTypography>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default Footer;
