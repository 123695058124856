import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SuiBox from '../../components/SuiBox';
import SuiButton from '../../components/SuiButton';
import CoverLayout from './components/CoverLayout';
import curved9 from '../../assets/images/curved-images/curved-6.jpg';
import { useAuth } from '../../auth-context/auth.context';
import { Google, Microsoft } from '@mui/icons-material';
import { useGoogleLogin } from '@react-oauth/google';
import User from '../../types/user';
import Auth from '../../api/auth';
import { Divider } from '@mui/material';
import { API_SERVER } from '../../config/constant';
import SuiInput from '../../components/SuiInput';

const SignIn: React.FC = () => {
  const history = useHistory();
  const { setUser, user } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailPasswordLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const tokens = await Auth.authenticateEmailPassword(email, password);
      setUser(tokens);
      history.push('/statement');
    } catch (error) {
      console.error('Failed to login with email and password', error);
    }
  };

  const authenticateUserGoogle = useGoogleLogin({
    onSuccess: async ({ code }: { code: string }) => {
      const tokens = await Auth.authenticateGoogle(code);
      console.log(tokens);
      setUser(tokens);
      history.push('/statement');
    },
    flow: 'auth-code',
    scope:
      'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/pubsub',
  });

  const authenticateUserMicrosoft = async () => {
    window.location.href = `${API_SERVER}auth/microsoft`;
  };


  return (
    <CoverLayout
      title="Your Smart File Assistant"
      description={`${user && user.refreshToken ? '' : 'Sign in to get started.'}`}
      image={curved9}
    >
      {user && user.refreshToken ? (
        <div>
          <h3 style={{ textAlign: 'center' }}>You are already signed in.</h3>
          <SuiBox mt={4} mb={1}>
            <SuiButton
              component="button"
              variant="gradient"
              buttonColor="info"
              fullWidth
              onClick={() => history.push('/statement')}
            >
              Let's go
            </SuiButton>
          </SuiBox>
        </div>
      ) : (
        <SuiBox>
          <SuiBox pt={2} pb={3} px={3}>
            <SuiBox component="form" role="form">
              <SuiBox mb={2}>
                <SuiInput
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </SuiBox>
              <SuiBox mb={2}>
                <SuiInput
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </SuiBox>
              <SuiButton
                variant="gradient"
                buttonColor="dark"
                fullWidth
                onClick={handleEmailPasswordLogin}
                type="submit"
              >
                Sign In
              </SuiButton>
            </SuiBox>
          </SuiBox>
          <Divider />
          <SuiButton
            component="label"
            buttonColor="secondary"
            startIcon={<Google />}
            onClick={authenticateUserGoogle}
            fullWidth
          >
            Authenticate Google
          </SuiButton>
          <Divider />
          <SuiButton
            component="label"
            buttonColor="secondary"
            startIcon={<Microsoft />}
            onClick={authenticateUserMicrosoft}
            fullWidth
          >
            Authenticate Microsoft
          </SuiButton>
        </SuiBox>
      )}
    </CoverLayout>
  );
};

export default SignIn;
