import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import SuiButton from "../../../../components/SuiButton";
import moment from "moment";
import { DocumentTypeEnum } from "../../../../utils/DocumentTypeEnum";
import { CircularProgress } from "@mui/material";

interface ButtonProps {
    disabled: boolean;
    color: 'warning' | 'secondary' | 'error' | 'success';
    variant: 'contained';
}

const updateSelectedRowIds = (params: GridRenderCellParams, rows: any[], setSelectedRowIds: (ids: string[]) => void) => {
    const selectedIds = rows.reduce((acc: string[], row) => {
        const checkbox = document.querySelector(`input[type="checkbox"][data-row-id="${row.id}"]`) as HTMLInputElement;
        if (checkbox && checkbox.checked) {
            acc.push(row.id);
        }
        return acc;
    }, []);
    console.log(selectedIds);
    setSelectedRowIds(selectedIds);
};

export const getColumns = (rows: any[], setSelectedDocumentId: (docId: string | null) => void, documentType: string, selectedRowIds: string[], setSelectedRowIds: (ids: string[]) => void): GridColDef[] => {
  
    let columns: GridColDef[] = [
        {
            field: 'checklist',
            headerName: '',
            width: 50,
            renderCell: (params: GridRenderCellParams) => (
                <input 
                    type="checkbox"
                    data-row-id={params.id}
                    disabled={params.row.daStatus === "Uploading"}
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent row selection
                        updateSelectedRowIds(params, rows, setSelectedRowIds);
                    }}
                />
            ),
        },
        { field: 'daFileName', headerName: 'name', width: 300 },
        { field: 'diaType', headerName: 'type', width: 150 },
        { field: 'daUploader', headerName: 'uploader', width: 150 },
        {
      field: 'daUploadDate',
      headerName: 'upload date',
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const formattedDate = moment(params.value).format('MM/DD/YYYY');
        return <div>{formattedDate}</div>;
      },
    },
    {
      field: 'daStatus',
      headerName: 'Status',
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const buttonProps: Record<string, ButtonProps> = {
          Uploading: {
            disabled: true,
            color: 'warning',
            variant: 'contained',
          },
          Analyzing: {
            disabled: true,
            color: 'secondary',
            variant: 'contained',
          },
          'Review Required': {
            disabled: false,
            color: 'error',
            variant: 'contained',
          },
          Done: {
            disabled: false,
            color: 'success',
            variant: 'contained',
          },
        };
        const { disabled, color, variant } = buttonProps[
          params.row.daStatus as keyof typeof buttonProps
        ] || { disabled: true, color: 'secondary', variant: 'contained' };
        const onClick = (e: React.MouseEvent) => {
          e.stopPropagation(); // Prevent row selection
          setSelectedDocumentId(String(params.id));
        };
        return (
          <SuiButton
            buttonColor={color}
            disabled={disabled}
            variant={variant}
            onClick={onClick}
          >
            {params.row.daStatus}
            {(params.row.daStatus === 'Uploading' || params.row.daStatus === 'Analyzing') && (
              <span style={{ marginLeft: 28 }}>
                <CircularProgress size={10} color="inherit"/>
              </span>
            )}
          </SuiButton>
        );
      },
    },
    ] as GridColDef[];
    if(documentType === 'statement') {
      columns.splice(2, 0, { 
        field: 'diaStatementDate', 
        headerName: 'statement date', 
        width: 150, 
        renderCell: (params: GridRenderCellParams) => {
          const date = params.value ? moment(params.value).format('YYYY/MM') : '';
          return <div>{date}</div>;
        },
      });
    }
    return columns;
}