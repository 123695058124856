import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import SuiBox from '../../../../components/SuiBox';
import SuiTypography from '../../../../components/SuiTypography';
import Card from '@mui/material/Card';
import DocumentViewer from '../DocumentViewer';
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';

import styles from '../../../../layouts/tables/styles';
import DocumentApi from '../../../../api/documentApi';
import { useAuth } from '../../../../auth-context/auth.context';
import { AttachFile, Delete, Download, Refresh } from '@mui/icons-material';
import Drive from '../../../../api/drive';
import CircularProgress from '@mui/material/CircularProgress';
import { DocumentSchema } from '../../../../types/document';
import { getColumns } from './tableData';
import { sortRows } from '../../../../utils/utils';
import { DocumentStatusEnum } from '../../../../utils/DocumentStatusEnum';
import { DriveFilesStatus } from '../../../../utils/DriveFilesStatus';
import { DocumentTypeEnum } from '../../../../utils/DocumentTypeEnum';
import { AccountConfig } from '../../../../types/accountConfig';
import AccountApi from '../../../../api/accountApi';
import { Configuration } from '../../../../types/accountConfig';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface DriveFiles {
  status: string;
  numDocumentsLeft: string | number;
}

const FilesTable: React.FC<{ documentType?: string }> = ({ documentType = null }) => {
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(null);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [rows, setRows] = useState<DocumentSchema[]>([]);
  const columns = getColumns(rows, setSelectedDocumentId, documentType, selectedRowIds, setSelectedRowIds);
  const [update, setUpdate] = useState<boolean>(false);
  const [downloadLink, setDownloadLink] = useState<string | null>(null);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [madeUnsavedChanges, setMadeUnsavedChanges] = useState<boolean>(false);
  const { user } = useAuth();
  const [accountConfig, setAccountConfig] = useState<AccountConfig | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const classes = styles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [newDriveFiles, setNewDriveFiles] = useState<DriveFiles>({
    status: DriveFilesStatus.loading,
    numDocumentsLeft: 0,
  });

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    AccountApi.getAccountConfig().then((config) => {
      setAccountConfig(config.data);
    });
  }, [user]);

  useEffect(() => {
    if (
      accountConfig &&
      accountConfig?.dashboard !== Configuration.limited &&
      accountConfig?.dashboard !== Configuration.disabled
    ) {
      const fetchData = async () => {
        const newDriveFilesResponse = await Drive.getNewDriveFiles(
          user.refreshToken,
          user.userEmail
        );
        setNewDriveFiles(newDriveFilesResponse.data);
      };
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      fetchData();

      intervalRef.current = setInterval(async () => {
        let newDriveFilesResponse = await Drive.getNewDriveFiles(
          user.refreshToken,
          user.userEmail
        );
        setNewDriveFiles(newDriveFilesResponse.data);
      }, 5000);

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [user, accountConfig]);

  useEffect(() => {
    const fetchDocuments = async () => {
      const limit = 1000000;
      const response = await DocumentApi.getDocumentsByType(documentType, user.refreshToken, limit);
      if (response) {
        let newRows = response.data.map((doc: DocumentSchema) => ({
          ...doc,
          id: doc._id,
        }));
        if (documentType !== DocumentTypeEnum.STATEMENT) {
          newRows = newRows.filter((row: DocumentSchema) => row.diaType !== DocumentTypeEnum.STATEMENT);
        }
        setRows(sortRows(newRows));
      }
    };
    fetchDocuments();
  }, [update, user.refreshToken, documentType]);

  useEffect(() => {
    if (selectedDocumentId === null) {
      setUpdate(!update);
    }
  }, [selectedDocumentId]);

  const uploadFiles = async (files: FileList) => {
    if (!files) return;

    if (intervalRef.current && documentType !== DocumentTypeEnum.STATEMENT) {
      clearInterval(intervalRef.current);
    }

    const uploadingRows: DocumentSchema[] = Array.from(files).map((file) => {
      const tempId = `${Date.now()}-${Math.random()}`;
      return {
        id: tempId,
        daFileName: file.name,
        diaType: documentType ? documentType : DocumentTypeEnum.DEFAULT,
        daUploader: user.userEmail,
        daStatus: DocumentStatusEnum.UPLOADING,
      };
    });

    const newAllRows = [...uploadingRows, ...rows];
    setRows(sortRows(newAllRows));

    const updateRow = (rowId: string, newData: Partial<DocumentSchema>) => {
      setRows((prev) => {
        const updated = [...prev];
        const targetIndex = updated.findIndex((r) => r.id === rowId);
        if (targetIndex !== -1) {
          updated[targetIndex] = { ...updated[targetIndex], ...newData };
        }
        return sortRows(updated);
      });
    };

    const uploadAndAnalyze = async (file: File, localTempId: string) => {
      try {
        const document = await DocumentApi.uploadFile({
          fileName: file.name,
          file,
          daUploader: user.userEmail,
          token: user.refreshToken,
          documentType: documentType || '',
        });

        updateRow(localTempId, { daStatus: DocumentStatusEnum.ANALYZING });

        const response = await DocumentApi.analyzeDocument(
          document.data.documentId,
          user.refreshToken,
          documentType || ''
        );

        updateRow(localTempId, {
          ...response.data,
          id: response.data._id,
        });
      } catch (err) {
        console.error('Error uploading file:', err);
        updateRow(localTempId, { daStatus: DocumentStatusEnum.ERROR });
      }
    };

    await Promise.all(
      Array.from(files).map((file, i) => {
        const localTempId = uploadingRows[i].id as string;
        return uploadAndAnalyze(file, localTempId);
      })
    );

    if (documentType !== DocumentTypeEnum.STATEMENT) {
      intervalRef.current = setInterval(async () => {
        try {
          const newDriveFilesResponse = await Drive.getNewDriveFiles(
            user.refreshToken,
            user.userEmail
          );
          setUpdate((prev) => !prev);
          setNewDriveFiles(newDriveFilesResponse.data);
        } catch (error) {
          console.error('Error fetching drive files:', error);
        }
      }, 5000);
    }
  };

  const processDocuments = async (): Promise<void> => {
    if (
      accountConfig &&
      accountConfig?.dashboard !== Configuration.limited &&
      accountConfig?.dashboard !== Configuration.disabled
    ) {
      setNewDriveFiles({
        status: 'loading...',
        numDocumentsLeft: newDriveFiles.numDocumentsLeft,
      });
      try {
        await DocumentApi.processNewFiles(user.refreshToken, user.userEmail);
      } catch (error: any) {
        if (error?.response?.status === 409) {
          // processing in progress on the server
        }
      }
      setNewDriveFiles({
        status: 'Processing',
        numDocumentsLeft: newDriveFiles.numDocumentsLeft,
      });
      await new Promise((resolve) => setTimeout(resolve, 5000));
      const newDriveFilesResponse = await Drive.getNewDriveFiles(
        user.refreshToken,
        user.userEmail
      );
      setNewDriveFiles(newDriveFilesResponse.data);
    }
  };

  const confirmDone = async (file: DocumentSchema) => {
    file.daStatus = DocumentStatusEnum.DONE;
    const updatedRows = [...rows];
    const rowIndex = updatedRows.findIndex((row) => row.id === file.id);
    if (rowIndex !== -1) {
      updatedRows[rowIndex] = file;
      setRows(sortRows(updatedRows));
    }

    if (documentType !== DocumentTypeEnum.STATEMENT) {
      const currentIndex = rows.findIndex((r) => r.id === file.id);
      let nextIndex = (currentIndex + 1) % rows.length;

      while (nextIndex !== currentIndex) {
        if (
          rows[nextIndex].daStatus === DocumentStatusEnum.REVIEW_REQUIRED ||
          rows[nextIndex].daStatus === DocumentStatusEnum.DONE
        ) {
          setSelectedDocumentId(rows[nextIndex].id as string);
          return;
        }
        nextIndex = (nextIndex + 1) % rows.length;
      }
      setSelectedDocumentId(null);
    }
  };

  const warnMadeUnsavedChanges = (): boolean => {
    if (madeUnsavedChanges) {
      setMadeUnsavedChanges(false);
      return window.confirm(
        'You have unsaved changes. Are you sure you want to continue?'
      );
    }
    return true;
  };

  const showNextPDF = (): void => {
    if (!warnMadeUnsavedChanges() || !selectedDocumentId) return;
    const currentIndex = rows.findIndex((r) => r.id === selectedDocumentId);
    let newIndex = (currentIndex + 1) % rows.length;

    while (newIndex !== currentIndex) {
      if (
        rows[newIndex].daStatus === DocumentStatusEnum.REVIEW_REQUIRED ||
        rows[newIndex].daStatus === DocumentStatusEnum.DONE
      ) {
        break;
      }
      newIndex = (newIndex + 1) % rows.length;
    }
    setSelectedDocumentId(rows[newIndex].id as string);
  };

  const showPrevPDF = (): void => {
    if (!warnMadeUnsavedChanges() || !selectedDocumentId) return;
    const currentIndex = rows.findIndex((r) => r.id === selectedDocumentId);
    let newIndex = (currentIndex - 1 + rows.length) % rows.length;

    while (newIndex !== currentIndex) {
      if (
        rows[newIndex].daStatus === DocumentStatusEnum.REVIEW_REQUIRED ||
        rows[newIndex].daStatus === DocumentStatusEnum.DONE
      ) {
        break;
      }
      newIndex = (newIndex - 1 + rows.length) % rows.length;
    }
    setSelectedDocumentId(rows[newIndex].id as string);
  };

  const handleDownloadExcel = async () => {
    setDownloading(true);
    try {
      const response = await DocumentApi.downloadExcel(user.refreshToken);
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    } finally {
      setDownloading(false);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    console.log('trigger automation');
  };

  const handleExtractStatements = async () => {
    const documentIds = rows
      .filter((r) => selectedRowIds.includes(r.id as string))
      .map((r) => r._id);

    const response = await DocumentApi.downloadStatement(user.refreshToken, documentIds);
    const excelData = new Uint8Array(response.data);
    const blob = new Blob([excelData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  };

  const deleteFiles = async () => {
    try {
      const documentIds = rows
        .filter((r) => selectedRowIds.includes(r.id as string))
        .map((r) => r._id);

      await DocumentApi.deleteFiles(documentIds, user.refreshToken);

      const newRows = rows.filter((row) => !selectedRowIds.includes(row.id as string));
      setRows(newRows);
      setSelectedRowIds([]);
      // setUpdate(!update);

    } catch (error) {
      console.error('Error deleting files:', error);
    }
  };

  const selectedFile = rows.find((r) => r.id === selectedDocumentId);

  return (
    <SuiBox py={3}>
      <DocumentViewer
        file={selectedFile}
        numFiles={rows.length}
        selectedDocumentId={selectedDocumentId}
        setSelectedDocumentId={setSelectedDocumentId}
        currentFileIndex={rows.findIndex((r) => r.id === selectedDocumentId)}
        confirmDone={() => selectedFile && confirmDone(selectedFile)}
        documentType={selectedFile?.diaType || documentType}
        showNextPDF={showNextPDF}
        showPrevPDF={showPrevPDF}
        madeUnsavedChanges={madeUnsavedChanges}
        warnMadeUnsavedChanges={warnMadeUnsavedChanges}
        setMadeUnsavedChanges={setMadeUnsavedChanges}
      />
      <Card>
        <SuiBox
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          p={3}
        >
          <SuiBox display='flex' alignItems='center' gap={2}>
            <SuiTypography variant='h6'>Recent Files</SuiTypography>
            {(accountConfig?.dashboard === Configuration.full ||
              accountConfig?.dashboard === Configuration.limited) && (
              <>
                <Button component='label' color='primary' startIcon={<AddIcon />}>
                  Upload {documentType ? documentType : 'file'}
                  <VisuallyHiddenInput
                    ref={fileInputRef}
                    type='file'
                    onChange={(e) => uploadFiles(e.target.files!)}
                    multiple
                  />
                </Button>

                <Button
                  color='primary'
                  onClick={deleteFiles}
                  disabled={selectedRowIds.length === 0}
                  startIcon={<Delete />}
                >
                  Delete Selected
                </Button>

                {documentType === DocumentTypeEnum.STATEMENT && (
                  <Button
                    color='primary'
                    onClick={handleExtractStatements}
                    disabled={selectedRowIds.length === 0}
                    startIcon={<Download />}
                  >
                    Extract Selected
                  </Button>
                )}
                {documentType !== DocumentTypeEnum.STATEMENT && (
                  <>
                    {accountConfig?.dashboard === Configuration.full && (
                      <Button
                        component='label'
                        color='secondary'
                        startIcon={<AttachFile />}
                        onClick={processDocuments}
                        disabled={
                          newDriveFiles.status === DriveFilesStatus.loading ||
                          newDriveFiles.status === DriveFilesStatus.processing
                        }
                      >
                        {newDriveFiles.status === DriveFilesStatus.loading
                          ? 'Loading...'
                          : newDriveFiles.status === DriveFilesStatus.processing
                          ? `Processing... ${newDriveFiles.numDocumentsLeft} left`
                          : `Process ${newDriveFiles.numDocumentsLeft} Docs from Drive`}
                        {(newDriveFiles.status === DriveFilesStatus.processing ||
                          newDriveFiles.status === DriveFilesStatus.loading) && (
                          <CircularProgress size={20} />
                        )}
                      </Button>
                    )}

                    <Button onClick={handleDownloadExcel}>
                      {downloading ? 'Exporting...' : 'Export Documents'}
                    </Button>

                    {downloadLink && !downloading && (
                      <span style={{ marginLeft: '10px' }}>
                        <a href={downloadLink} target='_blank' rel='noopener noreferrer'>
                          {downloadLink}
                        </a>
                      </span>
                    )}
                  </>
                )}
              </>
            )}
          </SuiBox>

          {accountConfig?.dashboard === Configuration.etransfer && (
            <>
              <Button
                color='primary'
                variant='contained'
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleClick}
                aria-controls={open ? 'automation-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
              >
                Trigger Automation
              </Button>
              <Menu
                id='automation-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'automation-button',
                }}
              >
                <MenuItem onClick={handleClose}>Automate Plexflow</MenuItem>
              </Menu>
            </>
          )}
        </SuiBox>

        <SuiBox className={classes.tables_table}>
          <DataGrid
            rows={rows}
            columns={columns}
            getRowId={(row) => row.id}
          />
        </SuiBox>
      </Card>
    </SuiBox>
  );
};

export default FilesTable;
